import { useRouter } from "next/navigation";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import Button from "@/components/common/PrimaryButton";

const RegisterMessage = () => {
  const { classes } = useStyles();
  const router = useRouter();

  const handleLogin = () => {
    router.push("/signin");
  };

  const handleSignup = () => {
    router.push("/register");
  };

  return (
    <div className={classes.container}>
      <Typography variant="body1" className={classes.text}>
        You must be logged into an account to create your own generations. You
        can sign up for free, all we need is your email address to prevent spam
        and allow you to manage your creations.
      </Typography>
      <div className={classes.authButtons}>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.authButton}
          onClick={handleLogin}
        >
          Login
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.authButton}
          onClick={handleSignup}
        >
          Sign Up
        </Button>
      </div>
    </div>
  );
};

export default RegisterMessage;
