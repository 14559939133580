"use client";

import { useState, useEffect } from "react";

import { useAppSelector } from "@/redux/store";

export default function useSafetyFilter(isUnsafe: boolean) {
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user.subscriptionType !== "free");
  const safeSearchSettingEnabled = user?.safeSearch;
  const [shouldFilter, setShouldFilter] = useState(isUnsafe);

  useEffect(() => {
    if (user && !safeSearchSettingEnabled && isSubscribed) {
      setShouldFilter(false);
      return;
    }
    setShouldFilter(isUnsafe);
  }, [user, isUnsafe, safeSearchSettingEnabled, isSubscribed]);

  return shouldFilter;
}
