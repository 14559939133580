"use client";

import { FC, useEffect, useRef } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import { useStyles } from "./styles";
import { useAppSelector } from "@/redux/store";

const AD_VARIANTS = {
  "header-in-screen": "7d8af978-2e30-4733-8f64-423faf6a6500",
  "top-sidebar-flex": "3dccf2a2-6f75-410c-9740-0192289df747",
  "pillar-right": "4a6edb08-6d0a-4139-a37f-92403fcd6482",
  "pillar-left": "bd53b420-1804-4bc4-88fa-86aea473a372",
  "middle-sidebar-flex": "8ab7fc3e-9750-4ab9-852e-a71bc8433782",
  "bottom-leaderboard": "855e5081-2586-496c-9495-91fde147843d",
  "sticky-sidebar": "bb47a847-c1a0-41e5-8be2-598fe27f6790",
  "footer-in-screen": "81d44713-e796-4bed-a0c1-7aad972c8e44",
  "in-content-repeatable": "50cedf95-5df4-4394-ae21-276ac25cb3d0",
};

export type AdVariant = keyof typeof AD_VARIANTS;

interface MonumetricAdProps {
  variant: AdVariant;
  className?: string;
}

const MonumetricAd: FC<MonumetricAdProps> = ({ variant, className }) => {
  const { classes, cx } = useStyles({ variant });
  const pathname = usePathname() as string;
  const searchParams = useSearchParams();
  const config = searchParams?.get("config");
  const hideAds = Boolean(config && config === "no-ads");
  const authLoading = useAppSelector((state) => state.user.authLoading);
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user.subscriptionType !== "free");
  const id = AD_VARIANTS[variant];
  const adRef = useRef<HTMLDivElement>(null);
  const adRendered = useRef(false);

  useEffect(() => {
    if (
      process.env.NODE_ENV === "development" || // Do not show ads in development
      authLoading || // Do not show ads during user authentication
      isSubscribed || // Do not show ads for premium users
      adRendered.current || // Only add the ad script once
      !adRef.current || // Ensure the div has been added to the DOM
      pathname === "/subscribe" || // Do not show ads on the subscribe page
      hideAds // Do not show ads when the no-ads config is present
    )
      return;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.setAttribute("data-cfasync", "false");
    script.async = true;
    script.innerHTML = `
    $MMT = window.$MMT || {};
    $MMT.cmd = $MMT.cmd || [];
    $MMT.cmd.push(function(){
      $MMT.display.slots.push(["${id}"]);
    });
  `;

    document.body.appendChild(script);
    adRendered.current = true;

    // Cleanup function to remove the script when the component unmounts
    return () => {
      if (script && document.body.contains(script)) {
        document.body.removeChild(script);
        adRendered.current = false;
      }
    };
  }, [authLoading, isSubscribed, id]);

  if (authLoading || isSubscribed || pathname === "/subscribe" || hideAds)
    return null;

  return (
    <div
      className={cx(classes.container, className)}
      id={`mmt-${id}`}
      ref={adRef}
    />
  );
};

export default MonumetricAd;
