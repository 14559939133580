import { makeStyles } from "tss-react/mui";

import { AdVariant } from "./";

const AD_DIMENSIONS: {
  [key: string]: {
    mobile: { width: number | string; height: number | string };
    tablet: { width: number | string; height: number | string };
    desktop: { width: number | string; height: number | string };
  };
} = {
  // M: 320x50,320x100
  // T: 728x90
  // D: 728x90
  "header-in-screen": {
    mobile: { width: 320, height: 50 },
    tablet: { width: 728, height: 90 },
    desktop: { width: 728, height: 90 },
  },
  // M: N/A
  // T: 300x250,160x600,300x600
  // D: 300x250,160x600,300x600
  "top-sidebar-flex": {
    mobile: { width: 0, height: 0 },
    tablet: { width: 160, height: 600 },
    desktop: { width: 160, height: 600 },
  },
  // M: N/A
  // T: N/A
  // D: 160x600
  "pillar-right": {
    mobile: { width: 0, height: 0 },
    tablet: { width: 0, height: 0 },
    desktop: { width: 160, height: 600 },
  },
  // M: N/A
  // T: N/A
  // D: 160x600
  "pillar-left": {
    mobile: { width: 0, height: 0 },
    tablet: { width: 0, height: 0 },
    desktop: { width: 160, height: 600 },
  },
  // M: N/A
  // T: 300x250,160x600,300x600
  // D: 300x250,160x600,300x600
  "middle-sidebar-flex": {
    mobile: { width: 0, height: 0 },
    tablet: { width: 160, height: 600 },
    desktop: { width: 160, height: 600 },
  },
  // M: 320x50,320x100
  // T: 728x90
  // D: 728x90
  "bottom-leaderboard": {
    mobile: { width: 320, height: 100 },
    tablet: { width: 728, height: 90 },
    desktop: { width: 728, height: 90 },
  },
  // M: N/A
  // T: 300x250,160x600,300x600
  // D: 300x250,160x600,300x600
  "sticky-sidebar": {
    mobile: { width: 0, height: 0 },
    tablet: { width: 300, height: 250 },
    desktop: { width: 300, height: 250 },
  },
  // M: 320x50
  // T: 728x90
  // D: 728x90
  "footer-in-screen": {
    mobile: { width: "auto", height: "auto" },
    tablet: { width: "auto", height: "auto" },
    desktop: { width: "auto", height: "auto" },
  },
  // M: 300x250,320x50,320x100
  // T: 728x90,300x250,320x50,320x100,468x60
  // D: 728x90,300x250,320x50,320x100,468x60
  "in-content-repeatable": {
    mobile: { width: 320, height: 50 },
    tablet: { width: 728, height: 90 },
    desktop: { width: 728, height: 90 },
  },
};

export const useStyles = makeStyles<{ variant: AdVariant }>()(
  (theme, { variant }) => ({
    container: {
      ...AD_DIMENSIONS[variant].mobile,
      [theme.breakpoints.up("md")]: {
        ...AD_DIMENSIONS[variant].tablet,
      },
      [theme.breakpoints.up("xl")]: {
        ...AD_DIMENSIONS[variant].desktop,
      },
    },
  })
);
