"use client";

import {
  PropsWithChildren,
  createContext,
  Dispatch,
  useReducer,
  useContext,
  useEffect,
} from "react";

import { Point } from "@/types/common";
import { AlertDialogType } from "@/types/alert";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { addAlertDialog } from "@/redux/actions";

const actionTypes = {
  SET_PROMPT: "SET_PROMPT",
  SET_SELECTED_IMAGE: "SET_SELECTED_IMAGE",
  SET_IMAGES: "SET_IMAGES",
  SET_IMAGE_MASK: "SET_IMAGE_MASK",
  SET_IS_EDITING_MASK: "SET_IS_EDITING_MASK",
  SET_STROKES: "SET_STROKES",
  SET_IS_DRAWING: "SET_IS_DRAWING",
} as const;

type State = {
  prompt: string;
  selectedImage: Blob | string | null;
  images: (Blob | string)[];
  imageMask: Blob | null;
  isEditingMask: boolean;
  strokes: Point[][];
  isDrawing: boolean;
};

type Action =
  | {
      type: typeof actionTypes.SET_PROMPT;
      payload: string;
    }
  | {
      type: typeof actionTypes.SET_SELECTED_IMAGE;
      payload: Blob | string | null;
    }
  | { type: typeof actionTypes.SET_IMAGES; payload: (Blob | string)[] }
  | { type: typeof actionTypes.SET_IMAGE_MASK; payload: Blob | null }
  | { type: typeof actionTypes.SET_IS_EDITING_MASK; payload: boolean }
  | { type: typeof actionTypes.SET_STROKES; payload: Point[][] }
  | { type: typeof actionTypes.SET_IS_DRAWING; payload: boolean };

const initialState: State = {
  prompt: "",
  selectedImage: null,
  images: [],
  imageMask: null,
  isEditingMask: false,
  strokes: [],
  isDrawing: false,
};

const GenContext = createContext<
  | {
      state: State;
      dispatch: Dispatch<Action>;
      updateImageMask: (mask: Blob | null) => void;
      deleteImage: (index: number) => void;
      addImage: (image: Blob | string) => void;
    }
  | undefined
>(undefined);

const genReducer = (state: State, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_PROMPT:
      return { ...state, prompt: action.payload };
    case actionTypes.SET_SELECTED_IMAGE:
      return { ...state, selectedImage: action.payload };
    case actionTypes.SET_IMAGES:
      return { ...state, images: [...action.payload] };
    case actionTypes.SET_IMAGE_MASK:
      return { ...state, imageMask: action.payload };
    case actionTypes.SET_IS_EDITING_MASK:
      return { ...state, isEditingMask: action.payload };
    case actionTypes.SET_STROKES:
      return { ...state, strokes: [...action.payload] };
    case actionTypes.SET_IS_DRAWING:
      return { ...state, isDrawing: action.payload };
    default:
      return state;
  }
};

export const GenProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(genReducer, initialState);
  const alerts = useAppSelector((state) => state.alert.alerts);
  const reduxDispatch = useAppDispatch();

  const updateImageMask = (mask: Blob | null) => {
    dispatch({ type: actionTypes.SET_IMAGE_MASK, payload: mask });
    dispatch({ type: actionTypes.SET_IS_EDITING_MASK, payload: false });
    if (state.selectedImage) {
      dispatch({
        type: actionTypes.SET_IMAGES,
        payload: [state.selectedImage],
      });
    }
  };

  const deleteImage = (index: number) => {
    const newImages = state.images.filter((_, i) => i !== index);
    dispatch({ type: actionTypes.SET_IMAGES, payload: newImages });
    dispatch({ type: actionTypes.SET_STROKES, payload: [] });
    dispatch({ type: actionTypes.SET_SELECTED_IMAGE, payload: null });
    dispatch({ type: actionTypes.SET_IMAGE_MASK, payload: null });
  };

  const addImage = (image: Blob | string) => {
    if (state.images.length > 0) {
      dispatch({ type: actionTypes.SET_IMAGE_MASK, payload: null });
    }

    if (state.images.length >= 9) {
      reduxDispatch(
        addAlertDialog({
          title: "Image Limit Reached",
          description: "You can only select 9 images per generation",
          prevButtonText: "Ok",
          singleOption: true,
          type: AlertDialogType.Default,
        })
      );
      return;
    }
    dispatch({
      type: actionTypes.SET_IMAGES,
      payload: [...state.images, image],
    });
  };

  // FIXME: This is a hack to reset the state when generation is successful
  useEffect(() => {
    if (
      alerts.some(
        (alert) =>
          typeof alert.text === "string" &&
          alert.text.includes("Generation Successful")
      )
    ) {
      dispatch({ type: actionTypes.SET_STROKES, payload: [] });
      dispatch({ type: actionTypes.SET_SELECTED_IMAGE, payload: null });
      dispatch({ type: actionTypes.SET_IMAGE_MASK, payload: null });
    }
  }, [alerts]);

  return (
    <GenContext.Provider
      value={{ state, dispatch, updateImageMask, deleteImage, addImage }}
    >
      {children}
    </GenContext.Provider>
  );
};

export const useGenContext = () => {
  const context = useContext(GenContext);

  if (context === undefined) {
    throw new Error("useGenContext must be used within a GenProvider");
  }

  return context;
};
