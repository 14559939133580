"use client";

import { FC, useRef, useState, useCallback } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import { useStyles } from "./styles";
import { LandingPage } from "@/types/landingPage";
import { useAppSelector } from "@/redux/store";
import { useGenContext } from "@/context/GenContext";
import { useExploreContext } from "@/context/ExploreContext";
import useExploreSearch from "@/hooks/useExploreSearch";
import ScrollArrow from "@/components/common/ScrollArrow";
import FeedGenerations from "../FeedGenerations";
import RegisterMessage from "@/components/feed/RegisterMessage";

interface FeedContentProps {
  landingPage?: LandingPage;
}

const FeedContent: FC<FeedContentProps> = ({ landingPage }) => {
  const { classes } = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const displayIncrement = isMobile ? 10 : 30;
  const [displayTotal, setDisplayTotal] = useState(displayIncrement);

  const { state } = useGenContext();
  const { prompt } = state;
  const { searchResults } = useExploreSearch(state.prompt, true, 1000);
  const { state: exploreState, updateSelectedTag } = useExploreContext();
  const showSearch = Boolean(prompt && searchResults.length > 0);
  const items = showSearch ? searchResults : exploreState.items;
  // const displayedItems = exploreState.items.slice(0, displayTotal);
  const displayedItems = items.slice(0, displayTotal);

  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const authLoading = useAppSelector((state) => state.user.authLoading);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (exploreState.loading || exploreState.items.length === 0) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          exploreState.items.length !== displayTotal
        ) {
          const newDisplayTotal =
            exploreState.items.length - displayTotal <= displayIncrement
              ? exploreState.items.length
              : displayTotal + displayIncrement;
          setDisplayTotal(newDisplayTotal);
        }
      });
      if (node) observer.current.observe(node);
    },

    [displayTotal, exploreState.loading, exploreState.items]
  );

  return (
    <div className={classes.container}>
      <div className={classes.content} ref={contentRef} id="feed-content">
        <h1 className={classes.pageTitle}>Idyllic For You Feed</h1>
        <div className={classes.contentInner}>
          <div className={classes.generationsContainer}>
            <div>
              {!isAuthenticated && !authLoading && prompt && (
                <RegisterMessage />
              )}
              {showSearch && (
                <p className={classes.searchHeading}>
                  Here are some community generations you might like, based on
                  your prompt
                </p>
              )}
              <FeedGenerations
                items={displayedItems}
                tags={exploreState.tags}
                loading={exploreState.loading}
                hideDisplayTags={!!landingPage || showSearch}
                hideHeading={showSearch}
                ref={lastElementRef}
                landingPageName={landingPage?.name}
                selectedTag={exploreState.selectedTag}
                updateSelectedTag={updateSelectedTag}
              />
            </div>
          </div>
        </div>
      </div>
      <ScrollArrow parentId="feed-content" color="secondary" bottom={16} />
    </div>
  );
};

export default FeedContent;
