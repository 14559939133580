"use client";

import { MouseEvent } from "react";
import HiddenIcon from "@mui/icons-material/VisibilityOff";

import { useStyles } from "./styles";
import { AlertDialogType } from "@/types/alert";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { addAlertDialog } from "@/redux/actions";

const SafeSearchIndicator = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user.subscriptionType !== "free");

  const handleClick = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (!isSubscribed) {
      dispatch(
        addAlertDialog({
          title: "Subscription Required",
          description: "Upgrade today to view all images in full HD!",
          nextButtonText: "Upgrade",
          type: AlertDialogType.SubscriptionRequired,
        })
      );
    } else {
      dispatch(
        addAlertDialog({
          title: "Configure Safe Search",
          description:
            "In order to view these images, you must disable safe search. The content of this images is potentially unsafe. Are you sure you want to proceed?",
          nextButtonText: "Disable Safe Search",
          type: AlertDialogType.ToggleSafeSearch,
        })
      );
    }
    return;
  };

  return <HiddenIcon className={classes.icon} onClick={handleClick} />;
};

export default SafeSearchIndicator;
