import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down("md")]: {
      maxWidth: "100vw",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(16),
    },
  },
  content: {
    flexGrow: 1,
    overflowY: "auto",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
  },
  pageTitle: {
    position: "absolute",
    width: "1px",
    height: "1px",
    margin: "-1px",
    padding: 0,
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    border: 0,
  },
  contentInner: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  generationsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  searchHeading: {
    fontSize: "1.3rem",
    fontWeight: 700,
    maxWidth: 500,
    margin: "1em auto",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      maxWidth: "100%",
    },
  },
  images: {
    width: "100%",
    paddingBottom: theme.spacing(2),
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(8),
    },
  },
  generations: {
    marginTop: theme.spacing(4),
  },
}));
