"use client";

import { forwardRef, ForwardedRef } from "react";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import { useStyles } from "./styles";
import { Explore } from "@/types/explore";
import MonumetricAd from "@/components/adverts/MonumetricAd";
import FeedTags from "../FeedTags";
import Masonry, { ResponsiveMasonry } from "@/components/layout/masonry";
import FeedCard from "../FeedCard";

interface FeedGenerationsProps {
  items: Explore[];
  loading: boolean;
  selectedTag: string | null;
  updateSelectedTag: (tag: string | null) => void;
  onImageClick?: () => void;
  hideDisplayTags?: boolean;
  hideHeading?: boolean;
  landingPageName?: string;
  tags?: string[];
  ref?: ForwardedRef<HTMLDivElement>;
}

const FeedGenerations = forwardRef<HTMLDivElement, FeedGenerationsProps>(
  (
    {
      items,
      loading,
      selectedTag,
      updateSelectedTag,
      onImageClick = () => {},
      hideDisplayTags = false,
      hideHeading = false,
      landingPageName,
      tags = [],
    },
    ref
  ) => {
    const { classes } = useStyles();
    const isLargeScreen = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up("lg")
    );

    const heading = !hideHeading ? (
      <h2 className={classes.title}>Explore {landingPageName ? "More" : ""}</h2>
    ) : null;

    return (
      <>
        {isLargeScreen && (
          <MonumetricAd
            variant="header-in-screen"
            className={classes.headerAd}
          />
        )}
        {!hideDisplayTags ? (
          <FeedTags
            selectedTag={selectedTag}
            updateSelectedTag={updateSelectedTag}
            tags={tags}
            className={classes.feedTags}
          />
        ) : (
          heading
        )}
        <ResponsiveMasonry
          columnsCountBreakpoints={{
            350: 2,
            800: 3,
            1000: 4,
            1200: 5,
            1500: 6,
            1900: 7,
          }}
        >
          <Masonry gutter={isLargeScreen ? "1em" : "0.25em"} gutterY={"1em"}>
            {loading
              ? [...new Array(isLargeScreen ? 30 : 10)].map((_, index) => (
                  <Skeleton
                    key={`feed-placeholder-${index + 1}`}
                    variant="rectangular"
                    className={classes.skeleton}
                  />
                ))
              : items.map((item, index) => (
                  <div
                    key={`feed-item-${index + 1}`}
                    onClick={onImageClick}
                    ref={items.length === index + 1 ? ref : null}
                  >
                    <div
                      data-track-resource=""
                      data-resource-id={
                        item.type === "image" ? item.meta.imageId : ""
                      }
                      data-resource-name="image"
                    >
                      <FeedCard item={item} />
                    </div>
                  </div>
                ))}
          </Masonry>
        </ResponsiveMasonry>
      </>
    );
  }
);

export default FeedGenerations;

FeedGenerations.displayName = "FeedGenerations";
