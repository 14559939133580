import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    width: "100%",
    maxWidth: 605,
    margin: "1em auto",
  },
  text: {
    textAlign: "left",
  },
  authButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(4),
  },
  authButton: {
    flex: 1,
  },
}));
