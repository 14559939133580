"use client";

import { FC } from "react";
import { useRouter, usePathname } from "next/navigation";
import Chip from "@mui/material/Chip";

import { useStyles } from "./styles";

interface FeedTagsProps {
  selectedTag: string | null;
  updateSelectedTag: (tag: string | null) => void;
  tags: string[];
  className?: string;
}

const FeedTags: FC<FeedTagsProps> = ({
  selectedTag,
  updateSelectedTag,
  tags,
  className,
}) => {
  const { classes, cx } = useStyles();
  const router = useRouter();
  const pathname = usePathname() as string;

  const handleTagClick = (newTag: string) => {
    if (selectedTag === newTag) {
      updateSelectedTag(null);
      router.push(pathname);
    } else {
      updateSelectedTag(newTag);
      router.push(`${pathname}?tag=${newTag}`);
    }
  };

  return (
    <div className={cx(classes.container, className)}>
      {selectedTag && tags.length > 0 && (
        <Chip
          label={selectedTag}
          className={classes.selectedTag}
          onClick={() => handleTagClick(selectedTag)}
        />
      )}
      <div className={classes.tags}>
        {tags
          .filter((tag) => tag !== selectedTag)
          .map((tag, index) => {
            return (
              <Chip
                key={`feed-image-${index + 1}`}
                label={tag}
                className={classes.tag}
                onClick={() => handleTagClick(tag)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default FeedTags;
