import { makeStyles } from "tss-react/mui";
import type { Theme } from "@mui/material/styles";

const getRight = (
  theme: Theme,
  spacing: number,
  position: "left" | "right" | "center"
) => {
  switch (position) {
    case "left":
      return "auto";
    case "right":
      return theme.spacing(spacing);
    case "center":
      return "50%";
  }
};

const getLeft = (
  theme: Theme,
  spacing: number,
  position: "left" | "right" | "center"
) => {
  switch (position) {
    case "left":
      return theme.spacing(spacing);
    case "right":
      return "auto";
    case "center":
      return "50%";
  }
};

export const useStyles = makeStyles<{
  position: "left" | "right" | "center";
  spacing: number;
  bottom: number;
}>()((theme, { position, spacing, bottom }) => ({
  scrollArrow: {
    position: "fixed",
    right: getRight(theme, spacing, position),
    left: getLeft(theme, spacing, position),
    bottom: theme.spacing(bottom),
    transform: position === "center" ? "translateX(-50%)" : "none",
    zIndex: 1000,
  },
}));
