"use client";

import { FC, MouseEvent } from "react";
import NextImage, { ImageProps as NextImageProps } from "next/image";

import { useStyles } from "./styles";
import useSafetyFilter from "@/hooks/useSafetyFilter";
import SafeSearchIndicator from "../SafeSearchIndicator";

type ImageProps = NextImageProps & {
  isUnsafe?: boolean;
};

const Image: FC<ImageProps> = ({ isUnsafe = false, ...nextImageProps }) => {
  const { classes } = useStyles();
  const blurImage = useSafetyFilter(isUnsafe);

  const handleContentMenu = (e: MouseEvent<HTMLDivElement>) => {
    if (blurImage) {
      e.preventDefault();
    }
  };

  const imageSrc = (nextImageProps.src as string).replace(
    "dreamhome.software", // TODO: Update remaining db records to use files.idyllic.app (e.g. dictionary)
    "idyllic.app"
  );

  return (
    <div className={classes.container} onContextMenu={handleContentMenu}>
      <NextImage
        {...nextImageProps}
        unoptimized={!imageSrc.includes("files.idyllic.app")}
        src={imageSrc}
        style={{
          ...nextImageProps.style,
          filter: blurImage ? "blur(15px)" : "none",
        }}
      />
      {blurImage && <SafeSearchIndicator />}
    </div>
  );
};

export default Image;
