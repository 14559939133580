import { ImageLoader } from "next/image";

export const bunnyLoader: ImageLoader = ({ src, width }) => {
  const id = typeof src === "string" ? src.split("/").pop() : 1;
  const url = new URL(`https://files.idyllic.app/files/static/${id}`);
  const params = url.searchParams;
  params.set("width", params?.get("width") || width?.toString() || "0");
  params.set("optimizer", "image");
  return url.href;
};

export default bunnyLoader;
