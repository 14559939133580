"use client";

import { FC, useState, useEffect, useCallback } from "react";
import Fab from "@mui/material/Fab";
import ArrowUp from "@mui/icons-material/ArrowUpward";

import { useStyles } from "./styles";

interface ScrollArrowProps {
  color?: "primary" | "secondary";
  parentId?: string;
  position?: "left" | "right" | "center";
  spacing?: number;
  bottom?: number;
}

const ScrollArrow: FC<ScrollArrowProps> = ({
  color = "primary",
  parentId,
  position = "right",
  spacing = 4,
  bottom = spacing,
}) => {
  const [container, setContainer] = useState<any>(null);
  const [showScroll, setShowScroll] = useState(false);
  const { classes } = useStyles({ position, spacing, bottom });

  const checkScrollTop = useCallback(() => {
    if (!showScroll && container && container.scrollTop > 500) {
      setShowScroll(true);
    } else if (
      showScroll &&
      container &&
      container.scrollTop &&
      container.scrollTop <= 500
    ) {
      setShowScroll(false);
    }
  }, [showScroll, setShowScroll, container]);

  const scrollTop = () => {
    container?.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    container?.addEventListener("scroll", checkScrollTop);
    return () => {
      container?.removeEventListener("scroll", checkScrollTop);
    };
  }, [checkScrollTop, container]);

  useEffect(() => {
    let updatedContainer: any = window;
    if (parentId) {
      const parentElement = document.getElementById(parentId);
      if (parentElement) updatedContainer = parentElement;
    }
    setContainer(updatedContainer);
  }, [parentId]);

  return (
    <Fab
      className={classes.scrollArrow}
      onClick={scrollTop}
      style={{
        display: showScroll ? "flex" : "none",
      }}
      color={color}
      aria-label="scroll to top"
    >
      <ArrowUp />
    </Fab>
  );
};

export default ScrollArrow;
