import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  skeleton: {
    minWidth: 200,
    minHeight: 200,
    aspectRatio: "1 / 1",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      minWidth: 150,
      minHeight: 150,
    },
  },
  headerAd: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  feedTags: {
    paddingTop: theme.spacing(4),
  },
  title: { marginBottom: theme.spacing(4) },
}));
