"use client";

import {
  PropsWithChildren,
  createContext,
  Dispatch,
  useReducer,
  useContext,
  useEffect,
  useRef,
} from "react";
import axios from "axios";

import { Explore } from "@/types/explore";

const actionTypes = {
  SET_FEED_LOADING: "SET_FEED_LOADING",
  SET_FEED_ITEMS: "SET_FEED_ITEMS",
  SET_FEED_TAGS: "SET_FEED_TAGS",
  SET_SELECTED_TAG: "SET_SELECTED_TAG",
  SET_SEARCH: "SET_SEARCH",
} as const;

type State = {
  loading: boolean;
  items: Explore[];
  tags: string[];
  selectedTag: string | null;
  search: string;
};

type Action =
  | { type: typeof actionTypes.SET_FEED_LOADING; payload: boolean }
  | { type: typeof actionTypes.SET_FEED_ITEMS; payload: Explore[] }
  | { type: typeof actionTypes.SET_FEED_TAGS; payload: string[] }
  | { type: typeof actionTypes.SET_SELECTED_TAG; payload: string | null }
  | { type: typeof actionTypes.SET_SEARCH; payload: string };

const initialState: State = {
  loading: false,
  items: [],
  tags: [],
  selectedTag: null,
  search: "",
};

const ExploreContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
  updateSelectedTag: (newTag: string | null) => void;
  fetchFeed: () => Promise<void>;
  fetchSearchContent: (newSearch?: string) => Promise<void>;
}>({
  state: initialState,
  dispatch: () => {},
  updateSelectedTag: (newTag: string | null) => {},
  fetchFeed: async () => {},
  fetchSearchContent: async (newSearch?: string) => {},
});

const exploreReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionTypes.SET_FEED_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.SET_FEED_ITEMS:
      return { ...state, items: action.payload };
    case actionTypes.SET_FEED_TAGS:
      return { ...state, tags: action.payload };
    case actionTypes.SET_SELECTED_TAG:
      return { ...state, selectedTag: action.payload };
    default:
      return state;
  }
};

interface ExploreProviderProps extends PropsWithChildren {
  initialState?: Partial<State>;
  origin?: string | null;
  landingPageSlug?: string;
  searchQuery?: string;
}

export const ExploreProvider = ({
  children,
  initialState: initialPropState = {},
  origin,
  landingPageSlug,
}: ExploreProviderProps) => {
  const [state, dispatch] = useReducer(exploreReducer, {
    ...initialState,
    ...initialPropState,
  });

  const hasFetchedOnClient = useRef(false);

  const fetchFeed = async () => {
    try {
      dispatch({ type: actionTypes.SET_FEED_LOADING, payload: true });
      let params = "";
      if (state.selectedTag) {
        params = `?tag=${state.selectedTag}`;
      } else if (landingPageSlug) {
        params = `?landingPageSlug=${landingPageSlug}`;
      } else if (origin) {
        params = `?origin=${origin}`;
      }
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/explore/feed${params}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.items.length === 0) {
        dispatch({ type: actionTypes.SET_FEED_LOADING, payload: false });
        return;
      }
      dispatch({
        type: actionTypes.SET_FEED_ITEMS,
        payload: response.data.items,
      });
      dispatch({
        type: actionTypes.SET_FEED_TAGS,
        payload: response.data.tags,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: actionTypes.SET_FEED_LOADING, payload: false });
    }
  };

  const fetchSearchContent = async (newSearch?: string) => {
    const updatedSearch = newSearch || state.search;
    try {
      if (newSearch) {
        dispatch({ type: actionTypes.SET_SEARCH, payload: newSearch });
      }
      dispatch({ type: actionTypes.SET_FEED_LOADING, payload: true });
      dispatch({ type: actionTypes.SET_FEED_TAGS, payload: [] });
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/explore/search?prompt=${updatedSearch}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.items.length === 0) {
        dispatch({ type: actionTypes.SET_FEED_LOADING, payload: false });
        return;
      }
      dispatch({
        type: actionTypes.SET_FEED_ITEMS,
        payload: response.data.items,
      });
      dispatch({ type: actionTypes.SET_FEED_TAGS, payload: [] });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: actionTypes.SET_FEED_LOADING, payload: false });
    }
  };

  const updateSelectedTag = (newTag: string | null) => {
    dispatch({ type: actionTypes.SET_FEED_LOADING, payload: true });
    dispatch({ type: actionTypes.SET_SELECTED_TAG, payload: newTag });
    dispatch({ type: actionTypes.SET_FEED_ITEMS, payload: [] });
    hasFetchedOnClient.current = false;
  };

  useEffect(() => {
    if (
      !state.search &&
      state.items.length === 0 &&
      !hasFetchedOnClient.current
    ) {
      hasFetchedOnClient.current = true;
      fetchFeed();
    }
  }, [state.items]);

  useEffect(() => {
    if (initialState.items.length > 0) {
      dispatch({
        type: actionTypes.SET_FEED_ITEMS,
        payload: [...initialState.items],
      });
    }
  }, [initialState]);

  useEffect(() => {
    if (state.search && state.items.length === 0) {
      fetchSearchContent();
    }
  }, [state.search]);

  return (
    <ExploreContext.Provider
      value={{
        state,
        dispatch,
        updateSelectedTag,
        fetchFeed,
        fetchSearchContent,
      }}
    >
      {children}
    </ExploreContext.Provider>
  );
};

export const useExploreContext = () => {
  const context = useContext(ExploreContext);

  if (!context) {
    throw new Error("useExploreContext must be used within an ExploreProvider");
  }

  return context;
};
