import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  selectedTag: {
    display: "inline-flex",
    flex: "0 0 auto",
    color: "#ffffff",
    background: theme.palette.secondary.main,
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.secondary.dark,
    },
  },
  tags: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    transition: "max-height 0.1s ease",
    overflowX: "auto",
    maxWidth: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tag: {
    display: "inline-flex",
    flex: "0 0 auto",
    color: theme.palette.primary.main,
    background: "#ececec",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
