import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#ffffff",
    fontSize: "3rem",
    cursor: "pointer",
    zIndex: 10,
    "&:hover": {
      opacity: 0.8,
    },
  },
}));
